:root {
    --overlay-padding: 31px;
    --font: 'Roboto Mono'
}

/*----------------------------------
|  OVERLAY                         |
----------------------------------*/
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: var(--default-color);
    padding: var(--overlay-padding);
    background-color: transparent;
    font-family: var(--font);
    box-sizing: border-box;
    visibility: hidden;
    z-index: 4;
}

.overlay .top-left,
.overlay .top-right,
.overlay .bottom-left,
.overlay .bottom-right {
    position: absolute;
    pointer-events: auto;
}

.overlay .top-left {
    left: var(--overlay-padding);
}

.overlay .top-right {
    right: var(--overlay-padding);
}

.overlay .bottom-left {
    left: var(--overlay-padding);
    bottom: var(--overlay-padding);
}

.overlay .bottom-right {
    right: var(--overlay-padding);
    bottom: var(--overlay-padding);
}

.overlay .bottom-left {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20px;
}

.overlay .top-left>*,
.overlay .bottom-left>* {
    position: relative;
    right: 15px;
    opacity: 0;
    transition: right 0.2s ease, opacity 0.4s ease;
}

.overlay .top-right>*,
.overlay .bottom-right>* {
    position: relative;
    left: 15px;
    opacity: 0;
    transition: left 0.2s ease, opacity 0.4s ease;
}

/*----------------------------------
|  OVERLAY => GROUP                |
----------------------------------*/
.overlay .group {
    position: relative;
    user-select: none;
}

.overlay .group.brand {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.overlay .group.brand .mini {
    display: flex;
    flex-direction: column;
}

.overlay .group.brand .avatar {
    width: 34px;
    height: 34px;
    content: url("https://avatars.githubusercontent.com/u/68818452?v=4");
    border: 1px solid #A9A9A9;
}

.overlay .group.brand .title {
    font-size: 0.7rem;
}

.overlay .group.brand .subtitle {
    font-size: 0.6rem;
    color: #A9A9A9;
}

/*----------------------------------
|  SKILLS                          |
----------------------------------*/

.overlay .group.brand .skills {
    display: flex;
    flex-direction: column;
    left: 0;
    gap: 5px;
    bottom: 0px;
    /* bottom: -10px; */
    font-size: 0.7rem;
    position: absolute;
    background-color: #121212;
    visibility: hidden;
    height: 1px;
    width: 100%;
    opacity: 0;
    transition: bottom 0.2s ease, opacity 0.4s ease;
}

.overlay .group.brand .skills .skill-category:nth-child(1) {
    margin-top: 5px;
}

.overlay .group.brand .skills .skill-category {
    display: flex;
    gap: 2px;
    flex-direction: column;
    width: 100%;
    max-height: 120px;
}

.overlay .group.brand .skills .skill-category .title {
    font-size: 0.7rem;
}

.overlay .group.brand .skills .skill-category .skill-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-color: #121212 transparent;
}

.overlay .group.brand .skills .skill-category .skill-list .skill {
    font-size: 0.6rem;
    color: #A9A9A9;
}

.overlay .group.brand .skills .skill:first-child {
    margin-top: 8px;
}

.overlay .group.brand .skills .skill a {
    color: white;
}

.overlay .skills .divider {
    background-color: #121212;
    height: 1px;
    width: 100%;
}

/*----------------------------------
|  SOUND                           |
----------------------------------*/
.overlay .group.sound {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}

.overlay .group.sound .speaker {
    position: relative;
    width: 17px;
    height: 17px;
    left: 5px;
    cursor: pointer;
}

.overlay .group.sound .mute {
    position: relative;
    top: 0;
    opacity: 1;
    visibility: visible;
    left: 0px;
    transition: left 0.2s ease, opacity 0.4s ease;
}

.overlay .group.sound .mute .one,
.overlay .group.sound .mute .two {
    background: white;
    position: absolute;
    width: 10px;
    height: 1px;
}

.overlay .group.sound .mute .one {
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(45deg);
}

.overlay .group.sound .mute .two {
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-45deg);
}

.overlay .group.sound .unmute {
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
    left: 5px;
    transition: left 0.2s ease, opacity 0.4s ease;
}

.overlay .group.sound .unmute .one,
.overlay .group.sound .unmute .two,
.overlay .group.sound .unmute .three {
    background: white;
    position: absolute;
    width: 5px;
    height: 1px;
}

.overlay .group.sound .unmute .one {
    top: 2px;
    left: 20px;
    transform: rotate(-30deg);
}

.overlay .group.sound .unmute .two {
    top: 8px;
    left: 20px;
}

.overlay .group.sound .unmute .three {
    top: 14px;
    left: 20px;
    transform: rotate(30deg);
}

/*----------------------------------
|  EXECUTE                         |
----------------------------------*/
.overlay .group.execute {
    display: flex;
    width: 17px;
    height: 17px;
    cursor: pointer;
}

/*----------------------------------
|  EXECUTE                         |
----------------------------------*/
.overlay .execute-ui {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    top: 50%;
}

.overlay .execute-ui .loader {
    border: 1px solid #F9F6EE;
    max-width: 250px;
    width: 250px;
    padding: 1px;
}

.overlay .execute-ui .loader-bar {
    background-color: white;
    height: 20px;
    width: 100%;
}

.overlay .execute-ui .loader-title {
    font-size: 0.8rem;
}

/*----------------------------------
|  STATS                           |
----------------------------------*/

/*----------------------------------
|  INFO                            |
----------------------------------*/
.overlay .group.info {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
}

.overlay .group.info .info {
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.overlay .group.info .info:hover {
    opacity: 0.7;
}

/*
.overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: var(--default-color);
    padding: var(--overlay-padding);
    background-color: transparent;
    font-family: var(--font);
    box-sizing: border-box;
    visibility: hidden;
    z-index: 4;
}

.overlay .top-left {
    position: absolute;
    left: var(--overlay-padding);
    pointer-events: auto;
}

.overlay .top-right {
    position: absolute;
    right: var(--overlay-padding);
    pointer-events: auto;
}

.overlay .bottom-left {
    position: absolute;
    bottom: var(--overlay-padding);
    left: var(--overlay-padding);
    display: flex;
    align-items: center;
    gap: 5px;
    height: 20px;
    pointer-events: auto;
}

.overlay .bottom-right {
    position: absolute;
    bottom: var(--overlay-padding);
    right: var(--overlay-padding);
    pointer-events: auto;
}

.overlay .top-left>* {
    right: 15px;
    opacity: 0;
    position: relative;
    transition: right 0.2s ease, opacity 0.4s ease;
}inter-events: auto;
}

.overlay .top-left>* {
    right: 15px;
    opacity: 0;
    position: relative;
    transition: right 0.2s ease, opacity 0.4s ease;
}

.overlay .top-right>* {
    left: 15px;
    opacity: 0;
    position: relative;
    transition: left 0.2s ease, opacity 0.4s ease;
}

.overlay .bottom-left>* {
    right: 15px;
    opacity: 0;
    position: relative;
    transition: right 0.2s ease, opacity 0.4s ease;
}

.overlay .bottom-right>* {
    left: 15px;
    opacity: 0;
    position: relative;
    transition: left 0.2s ease, opacity 0.4s ease;
}


.overlay .top-right>* {
    left: 15px;
    opacity: 0;
    position: relative;
    transition: left 0.2s ease, opacity 0.4s ease;
}

.overlay .bottom-left>* {
    right: 15px;
    opacity: 0;
    position: relative;
    transition: right 0.2s ease, opacity 0.4s ease;
}

.overlay .bottom-right>* {
    left: 15px;
    opacity: 0;
    position: relative;
    transition: left 0.2s ease, opacity 0.4s ease;
}
*/
/*
.overlay .brand {
    user-select: none;
    font-size: 0.8rem;
}

.overlay .brandSub {
    user-select: none;
    font-size: 0.6rem;
    color: lightgray;
}

.overlay .sound {
    display: flex;
    gap: 13px;
    width: fit-content;
    height: fit-content;
    font-size: 0.7rem;
    user-select: none;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.overlay .info {
    width: fit-content;
    height: fit-content;
    display: flex;
    user-select: none;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.hover.opacity {
    opacity: 1;
}

.hover.opacity:hover {
    opacity: 0.7
}


.overlay .info .information {
    border: 1px solid white;
    width: 16px;
    height: 16px;
    transition: transform 800ms ease;
}

.overlay .info .information:hover {
    transform-origin: center;
    transform: rotate(-10deg) scale(0.8);
}

.overlay .extra {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Roboto Mono", monospace;
    max-width: 350px;
    word-break: break-all;
    gap: 5px;
    color: white;
}

.overlay .extra .about {
    width: 350px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 1.1rem;
    border: 1px solid white;
    text-align: center;
}

.overlay .extra .description {
    width: 350px;
    word-break: break-all;
    font-size: 0.7rem;
}
*/
/*
.overlay .sound-icon-on {
    position: absolute;
    left: 70px;
}

.overlay .sound-icon-off {
    position: absolute;
    left: 68px;
}
*/