:root {
  --default-background-color: #020202;
  --default-color: ghostwhite;

  --outer-padding: 23px;
}

#root {
  width: 100%;
  height: 100%;
  z-index: 1;
}

html {
  background-color: var(--default-background-color);
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: var(--default-background-color);
}

code {
  font-family: 'Roboto Mono',
    monospace;
}