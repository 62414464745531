:root {
    --loading-background: var(--default-background-color);
    --loading-font: "Roboto Mono";
    --loading-font-size: 0.8rem;
    --loading-text-color: var(--default-color);

    --segment-color: var(--default-color);
    --succeed-text-color-hover: #BDBDBD;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    background-color: var(--loading-background);
    font-family: var(--loading-font);
    font-size: var(--loading-font-size);
    color: var(--loading-text-color);

    display: flex;
    justify-content: center;
    align-items: center;
}


.middle {
    display: inline-block;
    min-width: 47px;
    height: calc(47px / 1.618);
    transform-origin: center;
    transform: scale(1);
    transition: transform 0.5s ease-in-out;
}

.middle .loader {
    position: relative;
    width: 100%;
    height: calc(47px / 1.618);
    background-color: transparent;
    transition: opacity 0.3s ease-in;
}

.middle .loader .brand {
    position: absolute;
}

.middle .loader div {
    transition: width 0.3s ease, height 0.3s ease;
}

.middle .loader .segment-1 {
    position: absolute;
    top: 0;
    height: 1px;
    width: 100%;
    background-color: var(--segment-color);
}

.middle .loader .segment-2 {
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: var(--segment-color);
}

.middle .loader .segment-3 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    width: 100%;
    background-color: var(--segment-color);
}

.middle .loader .segment-4 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: var(--segment-color);
}
